/** @jsx jsx */
/* 3rd party imports */
import { FunctionComponent } from 'react';
import { jsx } from 'theme-ui';
import { graphql, PageProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import Gallery from '@browniebroke/gatsby-image-gallery';
import '@browniebroke/gatsby-image-gallery/dist/style.css';

/* 1st party imports */
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import Styles from '@/pages/gallery/styles';

interface Query {
	allFile: {
		edges: {
			node: {
				childImageSharp: {
					thumb: FluidObject;
					full: FluidObject;
				}
			}
		}[]
	}
}

const Page: FunctionComponent<PageProps<Query>> = ({ data }) => {
	const images = data?.allFile.edges.map(({ node }) => node.childImageSharp);
	return (
		<Layout>
			<SEO title="Gallery"/>
			<h1 sx={Styles.header}>Gallery</h1>
			<div sx={Styles.gallery}>
				<Gallery images={images}/>
			</div>
		</Layout>
	);
};

export const query = graphql`
  query ImagesForGallery {
    allFile(
		filter: { sourceInstanceName: { eq: "gallery" } }
	) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
            	...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1920) {
            	...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Page;
