/* 3rd party imports */
import { SxStyleProp } from 'theme-ui';

const header: SxStyleProp = {
	color: 'primary',
}

const gallery: SxStyleProp = {
	marginBottom: '1em',
}

export default { header, gallery };